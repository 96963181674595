import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ToasterService {
  private nileToast: any = new Subject<any>();
  nileToast$ = this.nileToast.asObservable();

  enableToast(message, type) {
    const nileToastInfo = {
      message: message,
      type: type,
    };
    this.nileToast.next(nileToastInfo);
  }

  error(message) {
    this.enableToast(message, "error");
  }

  success(message) {
    this.enableToast(message, "success");
  }

  warning(message) {
    this.enableToast(message, "warning");
  }

  info(message) {
    this.enableToast(message, "info");
  }
}
