import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ValidationService {

    validationErrorMessageMap: any = {};
    errorInfo: any = {};
    onFormSubmit = new BehaviorSubject({});

    setFormControl(formGroup, controlName, defaultValue: any = '', validation: any = [], isDisabled: boolean = false, lineNumber, dictionaryUtils) {

        let validationArr = [];

        if (validation) {
            for (let eachValidation of validation) {
                switch (eachValidation['condition']) {
                    case "required":
                        if (eachValidation['value']) {
                            validationArr.push(Validators.required);
                        }
                        break;
                    case "min":
                        validationArr.push(Validators.min(eachValidation['value']));
                        validationArr.push(Validators.pattern('[0-9]+'));
                        this.validationErrorMessageMap['pattern'] = 'Should be numeric';
                        break;
                    case "max":
                        validationArr.push(Validators.max(eachValidation['value']));
                        validationArr.push(Validators.pattern('[0-9]+'));
                        this.validationErrorMessageMap['pattern'] = 'Should be numeric';
                        break;
                    case "min_length":
                        validationArr.push(Validators.minLength(eachValidation['value']));
                        break;
                    case "max_length":
                        validationArr.push(Validators.maxLength(eachValidation['value']));
                        break;
                    case "pattern":
                        validationArr.push(Validators.pattern(eachValidation['value']));
                        break;
                    case "email":
                        validationArr.push(this.validateEmail);
                        break;
                    case "emails":
                        validationArr.push(this.validateEmails);
                        break;
                }

                this.validationErrorMessageMap[eachValidation['condition'].replace('_', '')] = eachValidation['error'];
            }
        }

        let control = new FormControl({ value: defaultValue, disabled: isDisabled }, validationArr);
        control['scope'] = this;
        control['lineNumber'] = lineNumber;
        control['dictionaryUtils'] = dictionaryUtils;
        formGroup.addControl(controlName, control);

    }

    setFormValue(formGroup, controlName, value) {

        formGroup.controls[controlName].setValue(value)
        formGroup.controls[controlName].markAsDirty();

        return this.isInputError(formGroup, controlName);
    }

    isInputError(formGroup, controlName) {

        let isError = formGroup.get(controlName).invalid && (formGroup.get(controlName).dirty || formGroup.get(controlName).touched);

        if (isError) {
            return this.getErrorMessage(formGroup, controlName);
        } else {
            return false;
        }

    }

    getErrorMessage(formGroup, controlName) {

        let errorMessage = '';

        if (formGroup.get(controlName).errors) {
            let errors = Object.keys(formGroup.get(controlName).errors);
            errorMessage = this.validationErrorMessageMap[errors[0]];
        }

        return errorMessage;
    }

    onTouched(formGroup, controlName) {

        if (!controlName || !formGroup) {
            return false;
        }

        formGroup.controls[controlName].markAsTouched();
    }

    validateForm(formGroup) {
        formGroup.markAllAsTouched();
        this.onFormSubmit.next(formGroup)
    }

    validateEmail(input) {

        let inputValue = input.value.toString().replace(/"/g, '');

        let availableVariables = [];

        if(input.scope && input.lineNumber && input.dictionaryUtils){
            let filterObj: any = {};
            filterObj.lineNumber = input.lineNumber;
            filterObj.flaternObj = false;
            filterObj.excludeGroups = ['ALL_USER_OBJ'];
            availableVariables = input.dictionaryUtils.getVariablesNameList(filterObj);
        }

        if (input.scope && input.scope.validateEmailValue(inputValue,availableVariables)) {
            return null;
        } else {
            return { emails: { value: input.value } };
        }
    }

    validateEmails(input) {


        if(!input.value){
            return null;
        }

        let inputValue = input.value.toString().replace(/"/g, '').trim();

        if (inputValue == "") {
            return null;
        }

        let availableVariables = [];

        if(input.scope && input.lineNumber && input.dictionaryUtils){
            let filterObj: any = {};
            filterObj.lineNumber = input.lineNumber;
            filterObj.flaternObj = false;
            filterObj.excludeGroups = ['ALL_USER_OBJ'];
            availableVariables = input.dictionaryUtils.getVariablesNameList(filterObj);
        }

        let emails = inputValue.replaceAll(";", ",").replaceAll("; ", ",").replaceAll(", ", ",").split(",");
        let valid = true;

        let emailPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (var i = 0; i < emails.length; i++) {

            if (input.scope && !input.scope.validateEmailValue(emails[i],availableVariables)) {
                valid = false;
                break;
            }

        }

        if (valid) {
            return null;
        } else {
            return { emails: { value: input.value } };
        }
    }

    validateEmailValue(inputvalue, availableVariables = []) {
        let emailPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (emailPattern.test(inputvalue)) {
            return true;
        }else if(availableVariables.length>0){
                if(inputvalue.indexOf('.')>-1){
                    inputvalue = inputvalue.split('.');
                    inputvalue = inputvalue[0];
                }

                let index = availableVariables.findIndex((variable)=>{return inputvalue==variable.value});
                if(index>-1){
                    return true;
                }else{
                    return false;
                }
        }else {
            return false;
        }
    }

}