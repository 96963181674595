/* Development Mode Cognito Points to Aqueralabs.com */

export const environment = {
  production: false,
  assets: 'https://assets.aqueralabs.com',
  _REGION: 'us-west-2',
  _ACCOUNT: '114180472798',
  BASEAPI: 'https://partnerapi-engine.aqueralabs.com',
  PARTNERAPI: 'https://partnerapi-lab.aqueralabs.com',
  ENGINEAPI: 'https://api.aqueralabs.com',
  UTILITIES_ENGINE: 'https://utilities.aqueralabs.com',
  SCHEMA_VALIDATIONAPI: 'https://ghusb759qe.execute-api.us-west-2.amazonaws.com/dev/schema-validation',
  SERVICE_API: '/services',
  TENANT_API: '/Tenants',
  USER_AGENT: 'Partner-Console',
  _DOMAIN: location.origin,
  _LANDINGPAGE: '/securehome/endpoints',
  _TIMEOUTPAGE: '/home/timeout',
  _LOGOUTPAGE: '/home',
  _NOTENANTPAGE: '/home/notenant',
  _expireTime: null,
  _Tenantlist: [],
  _TenantID: null,
  _PartnerID: null,
  _ACCESS_TOKEN: null,
  _UserAccess: null as any,
  refreshToken: null,
  _timeout: null,
  accessKey: null,
  idToken: null,
  AD_CONFIG: {
    'okta': {},
    'azure': {},
    'forgerock': {}
  },
  OKTA_DEFAULT_ISSUER: 'https://dev-131025.oktapreview.com/oauth2/default',
  OKTA_DEFAULT_CLIENTID: '0oa145wtyo0YSSRqa0h8',
  AZURE_DEFAULT_CLIENTID: 'c11f2c6e-0920-4d9d-a7ee-8ab860e96c8e',
  AZURE_DEFAULT_DIRECTORY_TENANTID: 'common',
  FORGEROCK_AM_URL: 'http://ec2-35-89-75-183.us-west-2.compute.amazonaws.com:8080/OpenAM',
  FORGEROCK_REALM_PATH: '',
  FORGEROCK_APP_URL: 'http://localhost:4200',
  FORGEROCK_JOURNEY_LOGIN: 'Login',
  FORGEROCK_WEB_OAUTH_CLIENT: 'WebOAuthClient',
  _ISERROR: null,
  DIRECTORY: null,
  TENANT_NAME: '',
  CLIENT_FOR_LOCALHOST: 'toyota',
  color: {
    success: '#22b2a8',
    warning: '#ddb01f',
    error: '#bd4149',
    info: '#42a3e5',
    overdue: '#ff9f40'
  }
};


// Prod test env
// export const environment = {
//   production: false,
//   assets: 'https://assets.aquera.io',
//   _REGION: 'us-west-2',
//   _ACCOUNT: '190980912085',
//   BASEAPI: 'https://partnerapi-engine.aquera.com',
//   PARTNERAPI: 'https://partnerapi.aquera.com',
//   ENGINEAPI: 'https://api.aquera.io',
//   UTILITIES_ENGINE: 'https://utilities.aquera.com',
//   SCHEMA_VALIDATIONAPI: 'https://api2.aquera.com/tenant',
//   SERVICE_API: '/services',
//   TENANT_API: '/Tenants',
//   USER_AGENT: 'Partner-Console',
//   _DOMAIN: location.origin,
//   _LANDINGPAGE: '/securehome/endpoints',
//   _TIMEOUTPAGE: '/home/timeout',
//   _LOGOUTPAGE: '/home',
//   _NOTENANTPAGE: '/home/notenant',
//   _expireTime: null,
//   _Tenantlist: [],
//   _TenantID: null,
//   _PartnerID: null,
//   _UserAccess: null as any,
//   refreshToken: null,
//   _timeout: null,
//   accessKey: null,
//   idToken: null,
//   AD_CONFIG: {
//     'okta': {},
//     'azure': {},
//     'forgerock': {}
//   },
//   OKTA_DEFAULT_ISSUER: 'https://dev-131025.oktapreview.com/oauth2/default',
//   OKTA_DEFAULT_CLIENTID: '0oa145wtyo0YSSRqa0h8',
//   AZURE_DEFAULT_CLIENTID: 'c11f2c6e-0920-4d9d-a7ee-8ab860e96c8e',
//   AZURE_DEFAULT_DIRECTORY_TENANTID: 'common',
//   FORGEROCK_AM_URL: 'http://ec2-35-89-75-183.us-west-2.compute.amazonaws.com:8080/OpenAM',
//   FORGEROCK_REALM_PATH: '',
//   FORGEROCK_APP_URL: 'http://localhost:4200',
//   FORGEROCK_JOURNEY_LOGIN: 'Login',
//   FORGEROCK_WEB_OAUTH_CLIENT: 'WebOAuthClient',
//   _ISERROR: null,
//   DIRECTORY: null,
//   CLIENT_FOR_LOCALHOST: 'ford',
//   TENANT_NAME: '',
//   color: {
//     success: '#22b2a8',
//     warning: '#ddb01f',
//     error: '#bd4149',
//     info: '#42a3e5',
//     overdue: '#ff9f40'
//   }
// };


