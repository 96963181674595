import {Injectable, EventEmitter} from "@angular/core";

@Injectable()
export class EmitterService {

    public event: EventEmitter<any> = new EventEmitter<any>();
    public tenantChange: EventEmitter<any> = new EventEmitter<any>();
    public endpointChange: EventEmitter<any> = new EventEmitter<any>();
    public orchestrationChange: EventEmitter<any> = new EventEmitter<any>();
    public sourceTypeChange: EventEmitter<any> = new EventEmitter<any>();
    public agentTabDisplay: EventEmitter<any> = new EventEmitter<any>();
    public groups: EventEmitter<any> = new EventEmitter<any>();

    public getEmitter() {
        return this.event;
    }

    public getTenantchangeEmitter() {
        return this.tenantChange;
    }

    public getEndpointchangeEmitter() {
        return this.endpointChange;
    }

    public getOrchestrationchangeEmitter() {
        return this.orchestrationChange;
    }

    public getSourcetypechangeEmitter() {
        return this.sourceTypeChange;
    }

    public agentDisplayEmitter() {
        return this.agentTabDisplay;
    }

    public getGroupEmitter() {          //application get groups
        return this.groups;
    }
}