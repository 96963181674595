import './polyfills.ts';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import * as Util from './app/utils/utils';
import * as _ from 'lodash';

if (environment.production) {
  enableProdMode();
  /* Avoid printing console in production */
  if (window) {
    window.console.log = function () { };
  }
}

//let client = location.host;
let client = (location.host).split(/:|\./)[0];

if (Util.isLocalNetwork(client) || client.includes('localhost')) {
  client = environment.CLIENT_FOR_LOCALHOST;
}

fetch(environment.PARTNERAPI + environment.TENANT_API + '/resource?iss=' + client)
  .then((response => response.json()))
  .then(res => {
    if (res && !_.isEmpty(res)) {
      if (_.has(res, 'directory')) {
        environment.DIRECTORY = res.directory;

        if (environment.DIRECTORY === 'Azure') {
          // Azure
          if (!_.has(res, 'clientId')) {
            environment._ISERROR = true;
          }
          environment.AD_CONFIG['azure']['clientId'] = res?.clientId;
          environment.AD_CONFIG['azure']['redirectUri'] = window.location.origin;
          environment.AD_CONFIG['azure']['directoryTenantId'] = res?.directoryTenantId || 'common';

          // Okta Sample value
          environment.AD_CONFIG['okta']['issuer'] = environment.OKTA_DEFAULT_ISSUER;
          environment.AD_CONFIG['okta']['clientId'] = environment.OKTA_DEFAULT_CLIENTID;
          environment.AD_CONFIG['okta']['redirectUri'] = window.location.origin + '/login/callback';
          environment.AD_CONFIG['okta']['logo'] = environment.assets + '/img/aquera-logo-blue.svg';

        } else if (environment.DIRECTORY === 'Okta') {
          // Okta
          if (!_.has(res, 'openId') || !_.has(res, 'clientId')) {
            environment._ISERROR = true;
          }
          environment.AD_CONFIG['okta']['issuer'] = res?.openId || environment.OKTA_DEFAULT_ISSUER;
          environment.AD_CONFIG['okta']['clientId'] = res?.clientId || environment.OKTA_DEFAULT_CLIENTID;
          environment.AD_CONFIG['okta']['redirectUri'] = window.location.origin + '/login/callback';
          environment.AD_CONFIG['okta']['logo'] = environment.assets + '/img/aquera-logo-blue.svg';

          //Azure sample config
          environment.AD_CONFIG['azure']['clientId'] = environment.AZURE_DEFAULT_CLIENTID;
          environment.AD_CONFIG['azure']['redirectUri'] = window.location.origin;
          environment.AD_CONFIG['azure']['directoryTenantId'] = environment.AZURE_DEFAULT_DIRECTORY_TENANTID;
        } else if (environment.DIRECTORY === 'ForgeRock DS' || environment.DIRECTORY === 'ForgeRock_IM' || environment.DIRECTORY === 'ForgeRock_IC' ) {

          // Okta Sample value
          environment.AD_CONFIG['okta']['issuer'] = environment.OKTA_DEFAULT_ISSUER;
          environment.AD_CONFIG['okta']['clientId'] = environment.OKTA_DEFAULT_CLIENTID;
          environment.AD_CONFIG['okta']['redirectUri'] = window.location.origin + '/login/callback';
          environment.AD_CONFIG['okta']['logo'] = environment.assets + '/img/aquera-logo-blue.svg';

          //Azure sample config
          environment.AD_CONFIG['azure']['clientId'] = environment.AZURE_DEFAULT_CLIENTID;
          environment.AD_CONFIG['azure']['redirectUri'] = window.location.origin;
          environment.AD_CONFIG['azure']['directoryTenantId'] = environment.AZURE_DEFAULT_DIRECTORY_TENANTID;

          // ForgeRock
          environment.AD_CONFIG['forgerock']['AM_URL'] = res.openAMUrl;
          environment.AD_CONFIG['forgerock']['FORGEROCK_REALM_PATH'] = res.realmPath || '';
          environment.AD_CONFIG['forgerock']['FORGEROCK_APP_URL'] = environment.FORGEROCK_APP_URL;
          environment.AD_CONFIG['forgerock']['FORGEROCK_JOURNEY_LOGIN'] = environment.FORGEROCK_JOURNEY_LOGIN;
          environment.AD_CONFIG['forgerock']['FORGEROCK_WEB_OAUTH_CLIENT'] = environment.FORGEROCK_WEB_OAUTH_CLIENT;
        }
      }
    } else {
      environment._ISERROR = true;
    }
    platformBrowserDynamic().bootstrapModule(AppModule);
  })
  .catch(error => {
    // Handle the error.

    //Okta
    environment.AD_CONFIG['okta']['issuer'] = environment.OKTA_DEFAULT_ISSUER;
    environment.AD_CONFIG['okta']['clientId'] = environment.OKTA_DEFAULT_CLIENTID;
    environment.AD_CONFIG['okta']['redirectUri'] = window.location.origin + '/login/callback';
    environment.AD_CONFIG['okta']['logo'] = environment.assets + '/img/aquera-logo-blue.svg';

    //Azure
    environment.AD_CONFIG['azure']['clientId'] = environment.AZURE_DEFAULT_CLIENTID;
    environment.AD_CONFIG['azure']['redirectUri'] = window.location.origin;
    environment.AD_CONFIG['azure']['directoryTenantId'] = environment.AZURE_DEFAULT_DIRECTORY_TENANTID;

    // ForgeRock
    environment.AD_CONFIG['forgerock']['AM_URL'] = environment.FORGEROCK_AM_URL;
    environment.AD_CONFIG['forgerock']['FORGEROCK_REALM_PATH'] = environment.FORGEROCK_REALM_PATH;
    environment.AD_CONFIG['forgerock']['FORGEROCK_APP_URL'] = environment.FORGEROCK_APP_URL;
    environment.AD_CONFIG['forgerock']['FORGEROCK_JOURNEY_LOGIN'] = environment.FORGEROCK_JOURNEY_LOGIN;
    environment.AD_CONFIG['forgerock']['FORGEROCK_WEB_OAUTH_CLIENT'] = environment.FORGEROCK_WEB_OAUTH_CLIENT;

    environment._ISERROR = true;
    platformBrowserDynamic().bootstrapModule(AppModule);
  })