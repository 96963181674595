/**
 *
 * This is the entry-way into the routing logic. This is the first component that's called when the app
 * loads.
 *
 */
import { Component, ViewEncapsulation, OnInit, Inject, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { environment } from "../environments/environment"
import { GatewayService } from "./service/gateway.service";
import { WebstorageService } from "./service/webstorage.service";
import { DomSanitizer } from "@angular/platform-browser";

import * as OktaSignIn from '@okta/okta-signin-widget';
import { OktaAuth, SigninWithRedirectOptions } from '@okta/okta-auth-js';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';

import { Config, Tokens, TokenStorage, UserManager, TokenManager, FRUser } from '@forgerock/javascript-sdk';

import { MsalService } from "@azure/msal-angular";
import '@aquera/nile-elements';
import * as _ from 'lodash';
import { EventType } from "@azure/msal-browser";
import { has, isEmpty, isObject } from "lodash";
import { ToasterService } from "./service/toaster.service";
import { TranslateService } from "@ngx-translate/core";
export let browserRefresh = false;

const DEFAULT_ORIGINAL_URI = window.location.origin;
@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.scss'],
    templateUrl: 'template/app.html',
    encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit, OnDestroy {
    public loading: boolean = true;
    signIn: any;
    isAuthenticated: any;
    windowIsError = false;

    subscription: any;
    _ForgeRockCode = "";
    _ForgeRockState = "";

    public currentLang: string = 'ja';
    selectedOrganizationId: string = '';

    constructor(private translate: TranslateService , public router: Router, public gateway: GatewayService, private route: ActivatedRoute, public storage: WebstorageService, private domSanitizer: DomSanitizer, private azureAuthService: MsalService, @Inject(OKTA_AUTH) public oktaAuth: OktaAuth, public authService: OktaAuthStateService, public toast: ToasterService) {
        this.translate.setDefaultLang(this.currentLang);
        this.translate.use(this.currentLang);
    }

    async ngOnInit() {

        this.route.queryParams.subscribe(async params => {
            if(params?.selectedOrganizationId) {
                this.selectedOrganizationId = params?.selectedOrganizationId;
                this.storage.storeData('_PartnerID', this.selectedOrganizationId);
            }

            if (params?.code != undefined && params?.state != undefined && (environment.DIRECTORY === 'ForgeRock DS' || environment.DIRECTORY === 'ForgeRock_IM' || environment.DIRECTORY === 'ForgeRock_IC')) {
                this._ForgeRockState = params.state;
                this._ForgeRockCode = params.code;
                this.handleForgeRockAuthentication(true);
            } else {
                this.handleForgeRockAuthentication(false);
            }
        });

        Config.set({
            clientId: environment.AD_CONFIG.forgerock["FORGEROCK_WEB_OAUTH_CLIENT"],
            redirectUri: window.location.origin + "/",
            scope: 'openid profile email',
            serverConfig: {
                baseUrl: environment.AD_CONFIG.forgerock["AM_URL"],
                timeout: 60000, // 90000 or less
            },
            realmPath: environment.AD_CONFIG.forgerock["FORGEROCK_REALM_PATH"],
            tree: environment.AD_CONFIG.forgerock["FORGEROCK_JOURNEY_LOGIN"],
        });

        if (environment._ISERROR) {
            this.windowIsError = true;
            this.router.navigate(['home/noclient']);
        }

        if (this.signIn) {
            this.signIn.remove();
        }

        if (environment.DIRECTORY === 'Azure') {
            // Set active acccount on page load
            const accounts = this.azureAuthService.instance.getAllAccounts();
            if (accounts.length > 0) {
                this.azureAuthService.instance.setActiveAccount(accounts[0]);
            }

            this.azureAuthService.instance.addEventCallback((event) => {
                if (has(event, 'error') && !isEmpty(event.error) && isObject(event.error) && has(event.error, 'errorMessage')) {
                    this.loading = false;
                    this.router.navigate(["home/error"], { state: { statusCode: event.error.name, body: event.error.errorCode, errorMessage: event.error.errorMessage } });
                }
                // set active account after redirect
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                    const account = event.payload.account;
                    this.azureAuthService.instance.setActiveAccount(account);
                    //this.router.navigate(['/securehome/partner-users']);
                    this.router.navigate(["home/select-partner"]);
                }
            })

            // handle auth redired/do all initial setup for msal
            this.azureAuthService.instance.handleRedirectPromise().then(authResult => {
                // Check if user signed in 
                const account = this.azureAuthService.instance.getActiveAccount();
                if (!account) {
                    // redirect anonymous user to login page 
                    this.azureAuthService.instance.loginRedirect();
                } else {
                    if ((window.location.pathname !== '/') && this.storage.getData('_PartnerID')) {
                        environment._PartnerID = this.storage.getData('_PartnerID');
                    } else {
                        this.resetPartnerAndTenantId();
                        this.router.navigate(["home/select-partner"]);
                    }
                }
            })
        } else if (environment.DIRECTORY === 'Okta') {
            this.oktaAuth.isAuthenticated().then((isAuthenticated) => {
                if (isAuthenticated) {
                    const token = this.oktaAuth.getAccessToken();
                    this.isAuthenticated = isAuthenticated;
                    if ((window.location.pathname !== '/') && this.storage.getData('_PartnerID')) {
                        environment._PartnerID = this.storage.getData('_PartnerID');
                    } else {
                        this.resetPartnerAndTenantId();
                        this.router.navigate(["home/select-partner"]);
                    }
                } else {
                    this.oktaAuth.signInWithRedirect();
                }
            });
        }
    }

    async handleForgeRockAuthentication(callback) {
        /*
        * If CallBack Then Try To Get Final Token
        */
        if (environment.DIRECTORY === 'ForgeRock DS' ||  environment.DIRECTORY === 'ForgeRock_IM' || environment.DIRECTORY === 'ForgeRock_IC') {
            if (callback) {
                let tokens = await TokenManager.getTokens({
                    query: {
                        code: this._ForgeRockCode, // Authorization code from redirect URL
                        state: this._ForgeRockState, // State from redirect URL
                    },
                });
                setTimeout(() => {
                    // this.router.navigate(["home/select-partner"]);
                    this.forgeRockSuccessNavigation();
                }, 1000);

            } else {
                const tokens: Tokens = await TokenStorage.get();
                if (tokens !== undefined) {
                    /*
                    * Check Local For Token If Available Redirect To Next Page
                    */
                    const currentTime = new Date().valueOf();
                    const tokenExpiryTime = tokens.tokenExpiry;
                    if(currentTime > tokenExpiryTime) {
                        this.logout();
                    } else {
                        this.forgeRockSuccessNavigation();
                    }
                } else {
                    /*
                    * For New User Send User To New Login Flow
                    */
                    TokenManager.getTokens({ forceRenew: true, login: 'redirect', support: 'modern' });
                }
            }
        }
    }

    forgeRockSuccessNavigation() {
        this.router.navigate(["home/select-partner"]);
    }

    async logout() {
        await FRUser.logout().finally(() => {
            TokenStorage.remove().then(() => {
                localStorage.removeItem("forgerock-sdk-WebOAuthClient");
                setTimeout(() => {
                    this.resetPartnerAndTenantId();
                    this.router.navigateByUrl('/');
                }, 1000);
            });
        });
    }

    resetPartnerAndTenantId() {
        this.storage.removeData('_TenantID');
        environment._PartnerID = null;
        environment._TenantID = null;

        if(this.selectedOrganizationId) return;
        this.storage.removeData('_PartnerID');
    }


    ngOnDestroy() {
        if (this.signIn) {
            this.signIn.remove();
        }

        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}