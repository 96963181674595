import {Injectable} from "@angular/core";
import * as _ from 'lodash';


@Injectable()
export class SchemaService {

    public resourceAttributeID = [
        { "id": "urn:ietf:params:scim:schemas:core:2.0:User", "prefix": null },
        { "id": "urn:ietf:params:scim:schemas:core:2.0:Group", "prefix": null },
        { "id": "urn:ietf:params:scim:schemas:ian:2.0:User", "prefix": "[IAN]" },
        { "id": "urn:ietf:params:scim:schemas:ian:2.0:Group", "prefix": "[IAN]" },
        { "id": "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User", "prefix": "[ENT]" },
        { "id": "urn:ietf:params:scim:schemas:extension:enterprise:2.0:Group", "prefix": "[ENT]" }
    ];

    constructor() {}

    getSchemaIDs(resourceTypedata, schemaIds) {

        if (!_.isNull(resourceTypedata) && _.has(resourceTypedata, 'Resources')) {

            resourceTypedata.Resources.forEach(schemasAttribute => {

                if (schemasAttribute.schema.indexOf(':Group') < 0) {                //eliminate group attributes

                    schemaIds.push(schemasAttribute.schema);                //Push the default core schema

                    if (schemasAttribute.schemaExtensions) {                 //If extenstion schema availble

                        schemasAttribute.schemaExtensions.forEach(extenstionSchemaAttribute => {

                            if (extenstionSchemaAttribute.schema.indexOf(':Group') < 0) {                //eliminate group attributes

                                schemaIds.push(extenstionSchemaAttribute.schema);
                            }

                        });
                    }
                }
            });

        }

    }

    
}