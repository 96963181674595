import {Injectable} from '@angular/core';
import {RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';
import { WebstorageService } from './webstorage.service';

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {

    handlers: {[key: string]: DetachedRouteHandle} = {};
    static tenantID:string;

    constructor(public storage: WebstorageService) {
        CustomReuseStrategy.tenantID = this.storage.getData('_TenantID');            // Cache clear if Tenant ID switch
    }

    calcKey(route: ActivatedRouteSnapshot) {                    /* Introduced to get the parent root level route e.g:[dashboard/""] */
        const url = route.pathFromRoot.map(x => x.url.map(u => u.path).join('/')).join(';');

        if (!url.length) { return undefined; }
        return url;
    }

    /**
     * Determines if this route (and its subtree) should be detached to be reused later.
    */
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        if (this.storage.getData('_TenantID') !== CustomReuseStrategy.tenantID) {    // Cache clear if Tenant ID switch
            return false;
        }
        if (!route.routeConfig || route.routeConfig.loadChildren) {     // if module then bypass
            return false;
        }

        return !!route.data && !!(route.data as any).shouldDetach; // true - reuse all routes
    }

    /**
     * Stores the detached route.
     */
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {

        if (!route.routeConfig || route.routeConfig.loadChildren) { return; }                     // if module then bypass
        if (route.routeConfig.data && route.routeConfig.data.shouldDetach) {
            const key = this.calcKey(route);
            if (key) { this.handlers[key] = handle; }
        }

    }

    /**
     * Determines if this route (and its subtree) should be reattached.
     */
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        if (route.data?.component === "LoginComponent") {           // When logout clear the cache
            this.handlers = {};
            return false;
        }

        if (this.storage.getData('_TenantID') !== CustomReuseStrategy.tenantID) {        // Cache clear if Tenant ID switch
            this.handlers = {};
            CustomReuseStrategy.tenantID = this.storage.getData('_TenantID');
            return false;
        }

        if (!route.routeConfig || route.routeConfig.loadChildren) { return false; }           // if module then bypass

        if (route.routeConfig.data && route.routeConfig.data.shouldDetach) {
            const key = this.calcKey(route);
            if (key) {  return !!this.handlers[key]; }
        }

        return false;
    }

    /**
    * Retrieves the previously stored route.
     */
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {

        if (!route.routeConfig || route.routeConfig.loadChildren) { return null; }                // if module then bypass

        if (route.routeConfig.data && route.routeConfig.data.shouldDetach) {
            const key = this.calcKey(route);
            if (key) { return this.handlers[key] || null; }
        }
        return null;

    }

    /**
     * Determines if a route should be reused.
     */
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }
}
