import * as _ from 'lodash';

export function generateUUID(): string {
    var d = new Date().getTime();
    var d2 =
      (typeof performance !== "undefined" &&
        performance.now &&
        performance.now() * 1000) ||
      0;
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
}
  
export function getFormFieldValue(formControlName, formGroup, isBoolean?) {
    if(_.has(formGroup.controls, formControlName)) {
        if(isBoolean) {
            return (formGroup.controls[formControlName].value === '') ? false : formGroup.controls[formControlName].value; 
        }
        return formGroup.controls[formControlName].value || '';
    }
    return '';
}

export function handleFormValueChanges(event, formControlName, formGroup) {
    formGroup.controls[formControlName].setValue(event);
}

export function handelOnBlurChanges(event, formControlName, formGroup) {
    formGroup.get(formControlName).markAsDirty();
    formGroup.get(formControlName).markAsTouched();
}

export function checkFormFieldDisabled(formControlName, formGroup) {
    if(_.has(formGroup.controls, formControlName)) {
        return formGroup.controls[formControlName].disabled;
    }
    return false;
}

export function checkValidation(formControlName, validationName,  formGroup, isSubmitted) {
    const errorObj = formGroup.controls[formControlName].errors;
    const isTouched = formGroup.controls[formControlName].touched;
    return  !_.isEmpty(errorObj) 
            && _.has(errorObj, validationName) 
            && errorObj[validationName] 
            && (isTouched || isSubmitted); 
}

export function isLocalNetwork(hostname) {
    return (['localhost', '127.0.0.1', '', '::1'].includes(hostname))
}