import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class UtilService {
    constructor() { }

    public getDisplayName(inputName, inputArray) {
        let counter = 0;
        let displayName = inputName;
        let isnameExists = false;

        do {
            isnameExists = inputArray.some((item) => { return item.name == displayName });

            if (isnameExists) {
                counter++;
                displayName = inputName + ' (' + counter + ')';
            }

        } while (isnameExists);

        return displayName;
    }

    public getRandomId() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return (
            s4() +
            s4() +
            "-" +
            s4() +
            "-" +
            s4() +
            "-" +
            s4() +
            "-" +
            s4() +
            s4() +
            s4()
        );
    }

    sortObject(obj,property) {
        obj.sort(function (a, b) {
            var x = (a[property]) ? a[property].toLowerCase() : a[property];
            var y = (b[property]) ? b[property].toLowerCase() : b[property];
            return x < y ? -1 : x > y ? 1 : 0;
        });

        return obj;
    }

    timeSince(timeInMilliSeconds) {

        let currenTtime = new Date();
        let seconds = Math.floor((currenTtime.getTime() - timeInMilliSeconds)/1000);
      
        var interval = seconds / 31536000;
      
        if (interval > 1) {
          return Math.floor(interval) + " years ago";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
          return Math.floor(interval) + " months ago";
        }
        interval = seconds / 86400;
        if (interval > 1) {
          return Math.floor(interval) + " days ago";
        }
        interval = seconds / 3600;
        if (interval > 1) {
          return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
          return Math.floor(interval) + " minutes ago";
        }
        interval = seconds / 10;
        if (interval > 1) {
          return "Few seconds ago";
        }
        //return Math.floor(seconds) + " seconds ago";
        return "Just now";
      }

    getImageUrlByAppName(name){
      if(!name){
        return environment.assets + '/logos/default.png';
      }else{
        let nameLower = name.replace(/\s+/g, "").toLowerCase();
        return environment.assets + '/logos/' + nameLower + '.png';
      }
    }

    getDistanceOfCoordinates(x1,y1,x2,y2){
      return Math.hypot(x2-x1, y2-y1);
    }

}