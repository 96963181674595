import { Routes } from "@angular/router";
import { OktaCallbackComponent } from "@okta/okta-angular";
import { AppComponent } from "./app.component";

export const routes: Routes = [
    {
        path: '',
        component: AppComponent
    },
    {
        path: 'login/callback',
        component: OktaCallbackComponent
    },
    {
        path: 'securehome',
        loadChildren: () => import(`./secure/module`).then(module => module.SecureHomeModule)
    },
    {
        path: 'home',
        loadChildren: () => import(`./public/auth/module`).then(module => module.PublicModule)
    }
];
