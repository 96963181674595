import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { environment } from '../environments/environment';
import { routes } from "./app.routes";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppCustomPreloader } from './app.routingloader';
import { ToasterService } from "./service/toaster.service";
import { WebstorageService } from "./service/webstorage.service";
import { GatewayService } from "./service/gateway.service";
import { UtilService } from "./service/utils.service";
import { EmitterService } from "./service/emitter.service";
import { ValidationService } from "./service/validation.service";

import {
    OktaAuthModule,
    OKTA_CONFIG
} from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';


import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { SchemaService } from "./service/schema.service";
import { CustomReuseStrategy } from "./service/route-reuse.service";

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const routing = RouterModule.forRoot(routes, { preloadingStrategy: AppCustomPreloader });


export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.AD_CONFIG['azure']['clientId'],
            redirectUri: environment.AD_CONFIG['azure']['redirectUri'],
            authority: 'https://login.microsoftonline.com/' + environment.AD_CONFIG['azure']['directoryTenantId'],
            postLogoutRedirectUri: environment.AD_CONFIG['azure']['redirectUri']
        },
        cache: {cacheLocation: 'localStorage'}
    });
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        routing,
        OktaAuthModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        MsalModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        RouterModule,
    ],
    providers: [
        EmitterService,
        GatewayService,
        SchemaService,
        UtilService,
        ValidationService,
        ToasterService,
        WebstorageService,
        AppCustomPreloader,
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
        {
            provide: OKTA_CONFIG,
            useFactory: () => {
                const oktaAuth = new OktaAuth(environment.AD_CONFIG['okta']);
                return { oktaAuth };
            }
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        MsalService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}


