export enum ProcessingStatus {
  PROCESSED = 'PROCESSED',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  PARTIALLY_PROCESSED = 'PARTIALLY PROCESSED'
}

export enum ProcessingStatusDisplay {
  PROCESSED = 'completed',
  PROCESSING = 'processing',
  FAILED = 'failed',
  PARTIALLY_PROCESSED = 'p-processed'
}

export enum InviteStatus {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
  CREATED = 'created',
  RESENT = 'resent',
  REMINDER = 'reminder',
  ESCALATION = 'escalation',
  DAILYREPORT = 'dailyreport',
  FAILED = 'failed'
}
