import {Injectable, Inject} from "@angular/core";

@Injectable()
export class WebstorageService {

    public data:any=[];

    constructor() {}

    storeData(key, val): void {
        localStorage.setItem(key, val);
        this.data[key]= localStorage.getItem(key);
    }
    
    getData(key): string {
        this.data[key]= localStorage.getItem(key);
        return this.data[key];
    }

    removeData(key): void {
        localStorage.removeItem(key);
    }

    removeAllData(): void {
        localStorage.clear();
    }
}